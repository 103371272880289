<template>
  <div class="container">
      <el-form :model="logform" :inline="true" class="demo-form-inline" :rules="logformrules" ref="logform">
        <el-form-item label="品牌" prop="brand" label-width="60px">
          <el-select v-model="logform.brand" clearable>
            <el-option key="osaio" label="osaio" value="osaio"></el-option>
            <el-option key="teckin" label="teckin" value="teckin"></el-option>
            <el-option key="victure" label="victure" value="victure"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="前缀" prop="prefix" label-width="60px">
            <el-input style="width:400px" v-model.trim="logform.prefix"></el-input>
        </el-form-item>
        <el-form-item label="" prop="" label-width="60px">
          <el-button type="primary" @click="LogValidate(GetLog)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table border v-bind:data="logList">
        <el-table-column label="key">
          <template v-slot="scope">
            <span>{{ scope.row.key }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template v-slot="scope">
            <el-button type="info" size="mini" @click="DownLog(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
     <div class="page">
      <el-button type="text" :disabled="logList.length<logform.rows" @click="NextPage" size="mini">下一页</el-button>
     </div>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      logform: {
        brand: '',
        lastKey: '',
        rows: 10,
        prefix: '',
      },
      logformrules: {
        brand: [{required: true, message: '请选择品牌', trigger: 'blur'}],
        prefix: [{required: true, message: '请输入前缀', trigger: 'blur'}]
      },
      logList: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    NextPage () {
      this.logform.lastKey = this.logList.length?this.logList[this.logList.length-1].key:''
      this.GetLog('page')
    },
    LogValidate (call) {
      this.$refs['logform'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    GetLog(type) {
      this.api.FileLogList({
        brand: this.logform.brand,
        lastKey: type=='page'?this.logform.lastKey:'',
        rows: this.logform.rows,
        prefix: this.logform.prefix,
      }).then(res => {
        if (res.data.code == 200) {
          this.logList = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    DownLog (item) {
      this.api.FileLogDown({
        brand: this.logform.brand,
        is_cdn: '',
        key: item.key
      }).then(res => {
        if (res.data.code == 200) {
          let imgurl = res.data.data.get_url
          let link = document.createElement('a')
          link.setAttribute('href', imgurl)
          link.setAttribute('target', '_blank')
          link.click()
          return
        }
        this.$message.error('下载失败' + res.data.msg)
      })
    },
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.page {
  float: right;
  margin-top: 10px
}
</style>
